export const API = {
    development: "https://api.assuarance.mayicard.com",
    test: "https://test.api.mayicard.com",
    production: "https://api.mayicard.com",
};

export const AppSettings = {
    COOKIE_TIMEOUT_IN_MINUTES: 30,
    APP_NAME: "Medlink",
    BG_TEXT: "bg-gray-100 dark:bg-zinc-800 dark:text-gray-200",
    BG_COLOR: "bg-gray-100 dark:bg-zinc-800",
    TEXT_COLOR: "text-gray-800 dark:text-gray-200",
    MEDGREEN: "#1e4854",
    PAGE_SPACING: "flex flex-col gap-6 p-6 bg-background rounded-lg"
};

export const setPageTitle = (title?: string) =>
    title ? `${title} | ${AppSettings.APP_NAME}` : AppSettings.APP_NAME;

export const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

export const siteConfig = {
    name: "Medlink",
    url: "https://app.medlink.me",
    ogImage: "https://app.medlink.me/og.jpg",
    description:
      "Medlink is a platform that connects patients with healthcare providers.",
    links: {
      twitter: "https://twitter.com/medlink",
      github: "https://github.com/medlink",
    },
  }
  
  export type SiteConfig = typeof siteConfig